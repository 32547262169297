import { useState, useEffect } from 'react'
import {nav, socials, features, posts, partners} from '../components/data';
import izzy from '../img/izzy.png';
import Header from '../components/header';
import Footer from '../components/footer';
import home from '../img/home.png'

import Squares from '../components/svgs'
export default function Home() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    console.log(typeof izzy)

    useEffect(() => {
        document.title = 'IzzyLabs';
      }, []);

    return (
        <div className="bg-gray-900">
            <Header/>

            <div className="relative isolate pt-14">
                <Squares/>
                <div className="mx-auto max-w-7xl px-6 py-16 sm:py-32  lg:px-8 ">
                    <div className="mx-auto  lg:mx-0 ">
                        {/* <div className="flex">
                            <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-white transition ease-in-out duration-300 hover:scale-125 hover:ring hover:ring-violet-300 active:bg-violet-700 bg-gray-900">
                                <span className="font-semibold text-indigo-600">We’re hiring</span>
                                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                                <a href="#" className="flex items-center gap-x-1">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    See open positions
                                    <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </a>
                            </div>
                        </div> */}
                        <h1 className="sm:mt-10 mt-2  text-5xl font-montserrat font-bold tracking-tight text-white text-center sm:text-7xl">
                        Bringing <span className="gradient-text">Web3</span> to Telegram</h1>
                        <p className="mt-10 sm:mx-48 text-xl leading-8 text-white font-montserrat text-center ">
                        Onboarding new people and brands in crypto by making it IZZY. We’re integrating Web3 functionality into the Telegram messenger to bring you a seamless NFT experience like never before.
                        </p>
                        {/* <p className="mt-6 text-lg leading-8 text-white font-montserrat">You can aknowledge more about our products below.</p> */}
                        <div className="mt-10 flex items-center gap-x-6 mx-auto justify-center">
                            <a
                                href="https://t.me/IzzynftsBot?start=website"
                                className="text-xl rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 px-10"
                            >
                                Join Our Alpha
                            </a>
                            {/* <a href="" className="text-xl rounded-md bg-transparent ring-2 ring-inset ring-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                Read Docs 
                            </a> */}
                        </div>
                        <div className="text-center mt-10 sm:mt-24">
                            <div className="container">
                                <h2 className="mt-10 sm:mx-48 text-2xl leading-8 text-white font-montserrat text-center">Supported by:</h2>
                                <div className='grid grid-cols-3 gap-x-5 justify-items-center items-center mt-6'>
                                    {Object.entries(partners).map(([partner, partner_data]) => (
                                        <a href={partner_data.href} target="_blank" rel="noopener noreferrer">
                                            <img className='max-h-12 img-fit w-auto opacity-50' src={partner_data.logo}/>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-900 ">
                <div className="mx-auto  ">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:justify-items-center lg:gap-y-0">
                            <div className="lg:row-start-2 lg:max-w-xl">
                                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                    NFT Functionality in Your Favorite Chat App.

                                </h2>
                                <p className="pt-4 sm:text-2xl text-xl leading-8 text-white font-montserrat text-left">
                                Get ready to experience the future of NFT trading and social interaction inside Telegram. Make seamless transactions and grow your digital collection with IzzyNFTs.
                                </p>
                                <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none pt-14">
                                    {features.map((feature) => (
                                        <div key={feature.name} className="relative">
                                            <dt className="ml-9 inline-block font-semibold text-white text-xl font-montserrat">
                                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                {feature.name}
                                            
                                            </dt>{' '}
                                            <br/>
                                            <dd className="inline text-md font-montserrat">{feature.description}
                                                {/* <p className="mt-6">
                                                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-400">
                                                        Learn more <span aria-hidden="true">→</span>
                                                    </a>
                                                </p> */}
                                            </dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                            <img
                                src={home}
                                alt="Product screenshot"
                                className="relative z-20 lg:row-span-4 sm:w-7/12  transition ease-in-out duration-300 hover:scale-105"
                            />
                            {/* <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
                                
                            </div> */}
                        </div>
                        <div
                            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/3 transform-gpu blur-3xl lg:bottom-[-11rem] lg:top-auto  lg:transform-gpu"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bg-gray-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">From the blog</h2>
                        <p className="mt-2 text-lg leading-8 text-white">
                            Learn how to grow your business with our expert advice.
                        </p>
                    </div>
                    
                    <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {posts.map((post) => (
                            <article
                                key={post.id}
                                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition ease-in-out duration-300 hover:scale-105"
                            >
                                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-white">
                                    <time dateTime={post.datetime} className="mr-8">
                                        {post.date}
                                    </time>
                                    <div className="-ml-4 flex items-center gap-x-4">
                                        <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="flex gap-x-2.5">
                                            <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                                            {post.author.name}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    <a href={post.href}>
                                        <span className="absolute inset-0" />
                                        {post.title}
                                    </a>
                                </h3>
                            </article>
                        ))}
                        
                    </div>
                    
                </div>
            </div> */}
            <Footer/>
        </div>


    )
}
