import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import Header from '../components/header';
import { useParams, Params } from "react-router-dom";
import {chainLists, _encrypted_auth_data} from '../components/data'

import {  useAccount } from "wagmi";
import {  ConnectKitButton, getDefaultConfig } from "connectkit";



function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function EVM() {
  const [selectedchainLists, setSelectedchainLists] = useState(chainLists[0])
  const { address, isConnecting, isDisconnected } = useAccount();

  const { hash } = useParams<{hash?: string}>();
  var hash_json = JSON.parse(atob(hash as string))
  const encrypted_auth_data:_encrypted_auth_data = {
      "id": hash_json.id,
      "address": "",
      "network": "",
      "username": hash_json.username
    }
  
  function Assemble() {
    encrypted_auth_data.network = selectedchainLists.network
    encrypted_auth_data.address = address
    console.log(encrypted_auth_data)
  }

  return (
    <div className="relative overflow-hidden">
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6 overflow-hidden" aria-hidden="true">
        <div
          className="overflow-hidden aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    <Header/>
    <main className='pt-14'>
        <div className="mx-auto mt-16 max-w-7xl px-6 p-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Hey, <span className="gradient-text">{encrypted_auth_data.username}!</span> This is a final stage, just keep going.
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-2xl leading-8 text-gray-300">
            Connect your EVM compatible wallet to the website and choose your priority chain
          </p>
          
          <div className="isolate mx-auto mt-10  max-w-2xl grid-cols-1 gap-8 ">
            

            <RadioGroup value={selectedchainLists} onChange={setSelectedchainLists}>
              <RadioGroup.Label className="text-base font-semibold leading-6 text-white">
                Chain List
              </RadioGroup.Label>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 lg:w-full items-center">
                {chainLists.map((chainList) => (
                  <RadioGroup.Option
                    key={chainList.id}
                    value={chainList}
                    className={({ active } : {active: Boolean}) =>
                      classNames(
                        active ? ` ring-4 ring-${chainList.color}` : `ring-2 ring-${chainList.color} `,
                        `relative flex cursor-pointer rounded-lg p-4  bg-white border-${chainList.color}`
                      )
                    }
                  >
                    {({ checked, active } : {checked: Boolean, active: Boolean}) => (
                      <>
                        <span className={classNames(`flex flex-1 justify-center items-center `)}>
                            <img src={chainList.logo} className='h-12'/>
                            
                        </span>
                        <CheckCircleIcon
                          className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-green-500  ')}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-indigo-600' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
            <div className="grid grid-cols-3 items-center  justify-items-center mx-6 my-12">
                <div className='grid grid-cols-1 items-center justify-items-center'>
                  <ConnectKitButton/>
                </div>
                <div>

                </div>
                <div className='grid grid-cols-1 items-center justify-items-center '>
                  <button 
                    className="rounded-xl bg-white px-3.5 py-1.5 text-lg font-semibold  text-gray-700 shadow-sm h-10 w-36 transition ease-in-out duration-300 hover:scale-105"
                    onClick={() => {Assemble()}}
                  >
                    Confirm
                  </button>
                </div>
              </div>
          </div>
          

        </div>
        
        
      </main>
    <div className="bg-[#ffffff] ring-[#ff0000] ring-[#1b4add] ring-[#9a2dcb] ring-[#007894] border-[#ff0000] border-[#1b4add] border-[#9a2dcb] border-[#007894] ">
    
    </div>
    
    </div>
    
    
  );
};