
import { socials,} from '../components/data';

export default function Footer() {
    return (
        <footer className="bg-gray-900 w-full " aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-24 lg:px-8 lg:pt-8">
          <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {socials.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 sm:text-xl text-md leading-5 text-gray-400 md:order-1 md:mt-0">
              &copy; 2023 IZZYLABS LLC. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
            )}