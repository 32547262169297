import {  useState } from 'react'

import Sidebar from '../../components/sidebar'
import Profile from '../../components/profile'
import { navigation,  userData,_encrypted_auth_data, truncate, wallets_dec, _wallets } from '../../components/data'


import Activity from '../../components/activity'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

type getChainImgF = (chain: string) => string;

function getChainImg(chain:string):string {
  var lt:string = '';
  wallets_dec.map((dec) => 
    {
      
      if (dec.name == chain) {
        console.log(dec.name, chain)
        console.log(dec.logotext)
        lt = dec.logotext
        return dec.logotext
      }
    }
  )
  return lt
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  navigation.map((item) => {
    if (item.name=='My Wallets') {
      item.current = true
    }
  })
  return (
    <>
      <div className="min-h-full">
        <Sidebar {...userData}/>
        <div className="flex flex-1 flex-col lg:px-64">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <Profile/>
{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}
            {Object.entries(userData.wallets).map(([chain_name, chain_data]) => (
            <div className="mt-8 hidden sm:block">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-xl font-medium leading-6 text-white">{chain_name}</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {chain_data.map((wallet) => (
                    <div key={wallet.address} className="overflow-hidden rounded-lg bg-gray-700 ">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            {/* <card.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl className=' justify-center'>
                              <dt className="truncate text-lg font-medium text-white">network: {wallet.priority}</dt>
                              <dt className="truncate text-lg font-medium text-white">name: {wallet.name}</dt>
                              <dt className="truncate text-lg font-medium text-white ">addr: {truncate(wallet.address)}</dt>
                              
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-800 px-5 py-3 grid grid-cols-2 gap-5 items-center justify-items-center ">
                        <div className=" rounded-full bg-gray-700 w-full h-8 items-center text-center ">
                          <a href={'/dashboard/nfts/' + wallet.address} className="font-medium text-white hover:text-gray-500 text-lg">
                            View NFTs
                          </a>
                        </div>
                        <div className="rounded-full bg-gray-700 w-full h-8 items-center text-center">
                          <a href={'/dashboard/assets/' + wallet.address} className="font-medium text-white hover:text-gray-500 text-lg">
                            View tokens
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
              </div>
            </div>
            ))}
{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}{/* BIG */}
{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}
            {Object.entries(userData.wallets).map(([chain_name, chain_data]) => (
            <div className=" sm:hidden">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-xl font-medium leading-6 text-white">{chain_name}</h2>
                <div className="flex flex-row   carousel-wrapper md:mt-0 pb-0 ">
                  {/* Card */}
                  {chain_data.map((wallet) => (
                    <div key={wallet.address} className="overflow-hidden rounded-lg bg-gray-700 m-6 min-w-2/3">
                      <div className="p-5 ">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            {/* <card.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl className=' justify-center'>
                              <dt className="truncate text-md font-medium text-white">network: {wallet.priority}</dt>
                              <dt className="truncate text-md font-medium text-white">name: {wallet.name}</dt>
                              <dt className="truncate text-md font-medium text-white ">addr: {truncate(wallet.address)}</dt>
                              
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-800 px-5 py-3 grid grid-cols-2 gap-5 items-center justify-items-center ">
                        <div className=" rounded-full bg-gray-700 w-full h-8 items-center text-center ">
                          <a href={'/dashboard/nfts/' + wallet.address} className="font-medium text-white hover:text-gray-500 text-lg">
                            View NFTs
                          </a>
                        </div>
                        <div className="rounded-full bg-gray-700 w-full h-8 items-center text-center">
                          <a href={'/dashboard/assets/' + wallet.address} className="font-medium text-white hover:text-gray-500 text-lg">
                            View tokens
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
              </div>
            </div>
            ))}
{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}{/* SMALL */}
            <Activity/>
          </main>
        </div>
      </div>
    </>
  )
}
