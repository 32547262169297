
import { pricing, faqs, _encrypted_auth_data} from '../components/data'
import Header from '../components/header'
import Footer from '../components/footer'
import { useParams } from "react-router-dom";



function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

export default function Auth() {
  const { hash } = useParams<{hash?: string}>();
  var hash_json = JSON.parse(atob(hash as string))
  const encrypted_auth_data:_encrypted_auth_data = {
      "id": hash_json.id,
      "address": "",
      "network": "",
      "username": hash_json.username
    }
  return (
    
    <div className="bg-gray-900">
      <Header/>
      
      <main className='pt-14'>
        {/* Pricing section */}
        <div className="mx-auto mt-16 max-w-7xl px-6 p-6 sm:mt-16 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            {/* <h1 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h1> */}
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              Hey, <span className="gradient-text">{encrypted_auth_data.username}!</span> Connect yourself with Izzy
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-xl leading-8 text-gray-300">
            Here you can choose network and than add your wallet to Izzy app.
          </p>
          
          <div className="isolate mx-auto mt-10  max-w-md grid-cols-3 gap-8 hidden sm:grid lg:min-w-full">
            {pricing.tiers.map((tier) => (
              <div
                key={tier.id}
                className={' ring-2 ' + classNames(
                  ` ring-${tier.color} bg-${tier.color} bg-opacity-30 ` + ' rounded-3xl p-4 sm:p-8 xl:p-10'
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <img src={tier.logo} className='h-12 img-fit'></img>
                  <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                    {tier.name}
                  </h2>
                    <p className=
                      {"rounded-full px-2.5 py-1 text-xs hidden sm:block font-semibold leading-5 text-white" + ` bg-${tier.color}`}
                    >
                       {tier.label }
                    </p>
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                
                <a
                  href={tier.href+hash}
                  aria-describedby={tier.id}
                  target="_blank"
                  className={classNames(
                      `bg-${tier.color} text-white shadow-sm transition text-xl sm:text-2xl ease-in-out duration-300 hover:scale-105 mt-6 block rounded-md py-2 px-3 text-center sm:mx-4 font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`
                  )}
                >
                  Connect
                </a>
                {/* <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul> */}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-row  ml-6 carousel-wrapper mt-6 md:mt-0 pb-0 sm:hidden" >
          {/* <a target="_blank" className="app-card flex items-center w-full cursor-pointer md:col-span-2 bg-gray-200 md:clickable-no-shadow">
            <div className="flex text-center justify-center w-full h-full">
              <div className="flex flex-col gap-3 lg:gap-4 2xl:gap-5 overflow-hidden items-center h-full justify-center">
                <img loading="lazy" src="https://injective-dotcom-files.s3.us-east-2.amazonaws.com/b16a8d9e-887a-4929-989f-894a873cb241.png" className="w-9 h-9 md:w-[34px] md:h-[34px] 4md:w-10 4md:h-10 lg:w-11 lg:h-11 xl:w-14 xl:h-14 2xl:w-16 2xl:h-16"/> 
                <span className="block w-full text-xs md:text-xs lg:text-tiny font-normal 2xl:font-medium overflow-hidden text-ellipsis whitespace-nowrap">Astroport</span>
              </div>
            </div>
          </a> */}
          {pricing.tiers.map((tier) => (
              <div
                key={tier.id}
                className={' ring-2 ' + classNames(
                  ` ring-${tier.color} bg-${tier.color} bg-opacity-30 ` + ' rounded-3xl p-4 sm:p-8 xl:p-10 m-6 min-w-1/2'
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <img src={tier.logo} className='h-12 img-fit'></img>
                  <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                    {tier.name}
                  </h2>
                    <p className=
                      {"rounded-full px-2.5 py-1 text-xs hidden sm:block font-semibold leading-5 text-white" + ` bg-${tier.color}`}
                    >
                       {tier.label }
                    </p>
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                
                <a
                  href={tier.href+hash}
                  aria-describedby={tier.id}
                  target="_blank"
                  className={classNames(
                      `bg-${tier.color} text-white shadow-sm transition text-xl sm:text-2xl ease-in-out duration-300 hover:scale-105 mt-6 block rounded-md py-2 px-3 text-center sm:mx-4 font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`
                  )}
                >
                  Connect
                </a>
                
              </div>
            ))}
          
        </div>
        
        {/* Testimonial section 
        <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
              <img className="h-12 self-start" src="https://tailwindui.com/img/logos/tuple-logo-white.svg" alt="" />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-white">
                  <p>
                    “Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare
                    arcu gravida natoque erat et cursus tortor consequat at. Vulputate gravida sociis enim nullam
                    ultricies habitant malesuada lorem ac. Tincidunt urna dui pellentesque sagittis.”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <img
                    className="h-14 w-14 rounded-full bg-gray-800"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="text-base">
                    <div className="font-semibold text-white">Judith Black</div>
                    <div className="mt-1 text-gray-400">CEO of Tuple</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
              <img className="h-12 self-start" src="https://tailwindui.com/img/logos/reform-logo-white.svg" alt="" />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-white">
                  <p>
                    “Excepteur veniam labore ullamco eiusmod. Pariatur consequat proident duis dolore nulla veniam
                    reprehenderit nisi officia voluptate incididunt exercitation exercitation elit. Nostrud veniam sint
                    dolor nisi ullamco.”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <img
                    className="h-14 w-14 rounded-full bg-gray-800"
                    src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="text-base">
                    <div className="font-semibold text-white">Joseph Rodriguez</div>
                    <div className="mt-1 text-gray-400">CEO of Reform</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
    */}
        {/* FAQ section */}
        <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <p className="mt-6 max-w-2xl text-base leading-7 text-gray-300">
            Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
            <a href="#" className="font-semibold text-indigo-400 hover:text-indigo-300">
              sending us an email
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </p>
          <div className="mt-20">
            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-base font-semibold leading-7 text-white">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-300">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </main>
      
      <Footer/>
                <div className="bg-[#9945FF] bg-[#007894] bg-[#0088CC] bg-[#5064fb] bg-[#f2a900]
                ring-2 ring-[#007894] ring-[#9945FF] ring-[#0088CC] ring-[#5064fb] ring-[#f2a900]
                text-[#007894] text-[#0088CC] text-[#9945FF] text-[#5064fb] text-[#f2a900]"
                ></div>
      
    </div>
  )
}
