import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'

import {
  CheckCircleIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid'
import Sidebar from '../../components/sidebar'
import Profile from '../../components/profile'
import { navigation, cards, userData, activityItems,_encrypted_auth_data } from '../../components/data'
import { useParams, Params } from "react-router-dom";
import templogo from "../../img/logo192.png"
import Activity from '../../components/activity'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  navigation.map((item) => {
    if (item.name=='Home') {
      item.current = true
    }
  })
  
  return (
    <>
      <div className="min-h-full">
        <Sidebar {...userData}/>
        <div className="flex flex-1 flex-col lg:px-64">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <Profile/>

            <div className="mt-8">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-white">Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {cards.map((card) => (
                    <div key={card.name} className="overflow-hidden rounded-lg bg-gray-700 ">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <card.icon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-white">{card.name}</dt>
                              <dd>
                                <div className="text-lg font-medium text-white">{card.amount}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-800 px-5 py-3">
                        <div className="text-sm">
                          <a href={card.href} className="font-medium text-white hover:text-gray-500">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='grid grid-cols-3 my-12 mx-12 gap-5'>
                  <button type="button" className=" rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Connect Twitter</button>
                  <button type="button" className=" rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Connect Discord</button>
                  <button type="button" className=" rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Connect Smth</button>
                </div>
              </div>
            </div>
            <Activity/>
          </main>
        </div>
      </div>
    </>
  )
}
