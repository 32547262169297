import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useState, useCallback } from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import CButton from '../components/cbutton'
import { useParams, Params } from "react-router-dom";
import {chainLists, _encrypted_auth_data} from '../components/data'
import solana_logo from '../img/Solana-2.jpg'
  // import { useChain } from "@cosmos-kit/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Cosmos()  {
  

  const { hash } = useParams<{hash?: string}>();
  var hash_json = JSON.parse(atob(hash as string))
  const encrypted_auth_data:_encrypted_auth_data = {
      "id": hash_json.id,
      "address": "",
      "network": "",
      "username": hash_json.username
    }
  
  function Assemble() {
    encrypted_auth_data.network = ""
    encrypted_auth_data.address = ""
    console.log(encrypted_auth_data)
  }

    

  return (
    <div className="relative overflow-hidden">
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6 overflow-hidden" aria-hidden="true">
        <div
          className="overflow-hidden aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    <Header/>
    <main className='pt-14 overflow-hidden'>
        <div className="relative mx-auto mt-16 max-w-7xl px-6 p-6 sm:mt-48 lg:px-8 overflow-hidden">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Hey, <span className="gradient-text">{encrypted_auth_data.username}!</span> This is a final stage, just keep going.
            </p>
          </div>
          <p className="mx-auto my-6 max-w-2xl text-center text-2xl leading-8 text-gray-300">
            Connect your Cosmos SDK wallet to the website and choose your priority chain
          </p>
          <div className="isolate mx-auto mt-10  max-w-2xl grid-cols-1 gap-8 justify-center justify-items-center grid ">
          <img src={solana_logo} className='lg:h-96 rounded-2xl h-48 sm:h-72'/>
              <div className="grid grid-cols-3 items-center  justify-items-center mx-6 my-12 ">
                <div className='grid grid-cols-1 items-center justify-items-center'>
                  {/* <WalletMultiButton className='custom w-36  text-xl text-center'/> */}
                </div>
                <div className='w-18'>

                </div>
                <div className='grid grid-cols-1 items-center justify-items-center '>
                  <button 
                    className="rounded-xl bg-white px-3.5 py-1.5 text-lg font-semibold  text-gray-700 shadow-sm h-10 w-36 transition ease-in-out duration-300 hover:scale-105"
                    onClick={() => {Assemble()}}
                  >
                    Confirm
                  </button>
                </div>
              </div>
          </div>
        </div>
      </main>
    </div>
  );
};