import {  ServerIcon, GiftIcon, HeartIcon, AtSymbolIcon} from '@heroicons/react/20/solid'
import solana_logo from "../img/solana_logo.png"
import ethereum_logo from '../img/eth_logo.png'
import cosmos_logo from '../img/cosmos_logo.png'
import ton_logo from '../img/ton_symbol.png'
import injective_logo from '../img/injective_logo.png'
import bitcoin_logo from '../img/btc_logo.png';
import osmosis_logo from '../img/osmosis_logo.png';

import ethereum_logo_text from '../img/ethereum-text2.png';
import polygon_logo_text from '../img/polygon_logotext_navy.png';
import optimism_logo_text from '../img/optimism_logotext.png';
import arbitrum_logo_text_navy from '../img/arbitrum_logotext_navy.png';
import arbitrum_logo_text from '../img/arbitrum_logotext.png';
import cosmos_logo_text from '../img/cosmos-logo-white.png';
import injective_logo_text from '../img/injective_logotext.png';
import osmosis_logo_text from '../img/osmosis_logotext.png';
import solana_logo_text from '../img/solanaLogo.png';
import ton_logo_text from '../img/ton_logo_dark_background.png'

import vechain from '../img/vechain.png'
import startuplisboa from '../img/startuplisboa.png';
import draperu from '../img/draperu.png'

import {
  ScaleIcon,
} from '@heroicons/react/24/outline'


import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  SignalIcon,
} from '@heroicons/react/24/outline'

export const nav = [
    { name: '', href: '#' },
    { name: '', href: '#' },
    { name: '', href: '#' },
    { name: '', href: '#' },
]

export const posts = [
    {
        id: 1,
        title: 'Boost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        author: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 2,
        title: 'Bo1ost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        author: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    }, {
        id: 3,
        title: 'Bo2ost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        author: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },

]

export const socials = {
    social: [
      
      {
        name: 'Twitter',
        href: 'https://twitter.com/izzy_labs',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: 'GitHub',
        href: 'https://github.com/Izzy-Labs',
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      
    ],
  }

type _pricing = {
  frequencies: {
    value: 'monthly',
    label: string,
    priceSuffix: string
  }[],
  tiers: {
    name: 'Solana' | 'EVM' | 'Cosmos' | 'TON' | 'Bitcoin',
    id: string,
    href: string,
    price: string,
    description: string,
    features: string[],
    color: string,
    label: string,
    logo: string
  }[]
}

export const pricing:_pricing = {
  frequencies: [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/transaction' },
  ],
  tiers: [
    {
      name: 'EVM',
      id: 'EVM',
      href: '/auth/EVM/',
      price: "0.5$",
      description: 'Ethereum Virtual Machine is the biggest blockchain family, NFTs and shitcoins as concept were born here',
      features: [
        'IzzyLabs supports Ethereum, Polygon, Optimism, Arbitrum networks',
        'IzzyLabs supports the most popular wallets but we recommend Metamask',
        'IzzyLabs supports viewing, maintaining and trading tokens and NFT',
      ],
      color: "[#007894]",
      label: 'Most popular',
      logo: ethereum_logo
    },
    {
      name: 'Solana',
      id: 'SOL',
      href: '/auth/Solana/',
      price: "0.5$",
      description: 'Modern, fast and cheap blockchain with huge NFT community. He\'s so fast so even need rest sometimes',
      features: [
        'IzzyLabs supports viewing, maintaining tokens and NFT. Trading support in progress',
        'IzzyLabs supports Phantom, Glow ... wallets', 
      ],
      color: "[#9945FF]", 
      label: 'Fast and Cheap',
      logo: solana_logo
    },
    
    {
      name: 'TON',
      id: 'TON',
      href: '/auth/atom/',
      price: "5$+",
      description: 'Ecosystem project launched by Telegram, designed to enable fast, low-cost transactions. NFTs are early',
      features: [
        'IzzyLabs supports viewing and maintaining tokens and NFTs. Trading not needed',
        'IzzyLabs supports the most popular wallets',
      ],
      color: "[#0088CC]",
      label: "Motherland",
      logo: ton_logo
    },
    {
      name: 'Cosmos',
      id: 'ATOM',
      href: '/auth/atom/',
      price: "5$+",
      description: 'Cosmos ecosystem has bunch of L1 networkds with bunch NFTs. Staking, low-cost transaction and diversity',
      features: [
        'IzzyLabs supports viewing and maintaining tokens and NFTs. Trading not needed',
        'IzzyLabs supports the most popular wallets',
      ],
      color: "[#5064fb]",
      label: "Layeromania",
      logo: cosmos_logo
    },
    {
      name: 'Bitcoin',
      id: 'BTC',
      href: '/auth/btc/',
      price: "5$+",
      description: '2023 is the year when degens united with bitcoiners to create THIS. Bitcoin NFTs without smartcontracts.',
      features: [
        'IzzyLabs supports viewing and maintaining tokens and NFTs. Trading not needed',
        'IzzyLabs supports the most popular wallets',
      ],
      color: "[#f2a900]",
      label: "We are so early",
      logo: bitcoin_logo
    },
  ],
}


export const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]


export const features = [
    {
        name: 'NFT and token portfolio. ',
        description: 'NFTs synced with @usernames. We make it easy for people to share their collections with others.',
        icon: AtSymbolIcon,href: '',
    },
    {
        name: 'NFT Airdrops.',
        description: 'For brands, programs, influencers. Share drops inside Telegram via unique links',
        icon: GiftIcon,href: '',
    },
    {
        name: 'More coming soon',
        description: 'We’re actively developing more features.',
        icon: HeartIcon,href: '',
    },
]

export type _encrypted_auth_data = {
  id: string,
  address: string | undefined,
  username: string,
  network: string | undefined
}

type _deployments = {
  id: number,
  href: string,
  projectName: string,
  teamName: string,
  status: 'offline' | 'online' | 'error',
  statusText: string,
  description: string,
  environment: 'Preview' | 'Production'
}[]

export const navigation = [
  { name: 'Home', href: '/dashboard/home/', icon: FolderIcon, current: false },
  { name: 'My Wallets', href: '/dashboard/wallets/', icon: ServerIcon, current: false },
  { name: 'My Assets', href: '/dashboard/assets/', icon: SignalIcon, current: false },
  { name: 'NFT Gallery', href: '/dashboard/nfts/', icon: GlobeAltIcon, current: false },
  { name: 'Activity', href: '/dashboard/activity/', icon: ChartBarSquareIcon, current: false },
  { name: 'Settings', href: '/dashboard/settings/', icon: Cog6ToothIcon, current: false },
]
export const teams = [
  { id: 1, name: 'Unnamed (0x123...Ed2)', href: '#', initial: 'U', current: false },
  { id: 2, name: 'MAYC cage (0xcCC...A2a', href: '#', initial: 'M', current: false },
  { id: 3, name: 'Shitcoins storage', href: '#', initial: 'S', current: false },
]
export const statuses = {
  offline: 'text-gray-500 bg-gray-100/10',
  online: 'text-green-400 bg-green-400/10',
  error: 'text-rose-400 bg-rose-400/10',
}
export const environments = {
  Preview: 'text-gray-400 bg-gray-400/10 ring-gray-400/20',
  Production: 'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
}
export const deployments:_deployments = [
  {
    id: 1,
    href: '#',
    projectName: 'ios-app',
    teamName: 'Planetaria',
    status: 'offline',
    statusText: 'Initiated 1m 32s ago',
    description: 'Deploys from GitHub',
    environment: 'Preview',
  },
  // More deployments...
]
export const activityItems = [
  {
    user: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    projectName: 'ios-app',
    commit: '2d89f0c8',
    branch: 'main',
    date: '1h',
    dateTime: '2023-01-23T11:00',
  },
  // More items...
]

type _chainLists = {
  id: number,
  title: string,
  logo: string,
  color: string,
  network: string
}[]

export const chainLists:_chainLists = [
  { id: 1, title: 'Ethereum', network: 'eth', logo: ethereum_logo_text, color: '[#007894]' },
  { id: 2, title: 'Polygon', network: 'matic', logo: polygon_logo_text, color: '[#9a2dcb]' },
  { id: 3, title: 'Optimism', network: 'op', logo: optimism_logo_text, color: '[#ff0000]' },
  { id: 4, title: 'Arbitrum One', network: 'arbi', logo: arbitrum_logo_text_navy, color: '[#1b4add]' },
]

export type _userData = {
  telegram_data:  {
    id: number,
    username: string
  },
  wallets: {
    EVM: {
      address: string,
      priority: string,
      href: string,
      name: string
    }[],
    Solana: {
      address: string,
      priority: string,
      href: string,
      name: string
    }[],
    Cosmos: {
      address: string,
      priority: string,
      href: string,
      name: string
    }[],
    Bitcoin: {
      address: string,
      priority: string,
      href: string,
      name: string
    }[],
    TON: {
      address: string,
      priority: string,
      href: string,
      name: string
    }[],
  }
  
}

export const userData:_userData = {
  telegram_data: {id: 11, username: "@rg_eth"},
  wallets: {
    EVM: [
      {
        address:"0x6685844A8544264f0adBcb831Bf38487662432fE",
        priority: "Ethereum",
        href: "s",
        name: "main"
      },
      {
        address: "0x5cB8cCCF6716b2aA6Cf21feD6F6Cd6f058D2e55b",
        priority: "Arbitrum",
        href: "",
        name: "second"
      },
      {
        address: "0x128cCCF26716b2aA6Cf21feD6F6Cd6f058D2e55b",
        priority: "Optimism",
        href: "",
        name: "old"
      },
      {
        address: "0x121212122126b2aA6Cf21feD6F6Cd6f058D2e55b",
        priority: "Polygon",
        href: "",
        name: "opti"
      },
    ],
    Solana: [
      {
        address: "DM4qgysCqKPZnZV4fTebQZEf8vkTSrEgb3CkwGvJgmuo",
        priority: "Solana",
        href: "",
        name: "solele"
      }
    ],
    Cosmos: [
      {
        address: "inj1tjuvenm8z6e25m8jrlkk7mxk7pvd9e2mjlaayh",
        priority: "Injective",
        href: "",
        name: "injur"
      },
      {
        address:"cosmos1hr9u0x8kmsanz88g2d6hd06zm2m0tlayqnqjlg",
        priority: "Cosmos",
        href: "",
        name: "cosma"
      },
      {
        address:"osmo1hr9u0x8kmsanz88g2d6hd06zm2m0tlayggnzf6",
        priority: "Osmosis",
        href: "",
        name: "noname"
      },
    ],
    Bitcoin: [
      {
        address: "DM4qgysCqKPZnZV4fTebQZEf8vkTSrEgb3CkwGvJgmuo",
        priority: "Bitcoin",
        href: "",
        name: "sbitele"
      }
    ],
    TON: [
      {
        address: "DM4qgysCqKPZnZV4fTebQZEf8vkTSrEgb3CkwGvJgmuo",
        priority: "TON",
        href: "",
        name: "toonele"
      }
    ],
  }
} 

type _cards = {
  name: string,
  href: string,
  icon: any,
  amount: string
}[]

export const cards:_cards = [
  { name: 'Est. balance', href: '#', icon: ScaleIcon, amount: '$30,659.45' },
  { name: 'Wallets ct.', href: '#', icon: ScaleIcon, amount: '3' },
  { name: 'NFTs ct.', href: '#', icon: ScaleIcon, amount: '12' },
  { name: 'Chains ct.', href: '#', icon: ScaleIcon, amount: '3' },
  { name: 'PnL', href: '#', icon: ScaleIcon, amount: '+3%' },
  { name: 'Izzy points', href: '#', icon: ScaleIcon, amount: '1212' },
  // More items...
]

export function truncate(address:string) {
  return address.slice(0, 5) + "..." + address.slice(38)
}

export type _wallets = {
    logotext: string,
    logo: string,
    color: string,
    name: string
}[]

export const wallets_dec:_wallets = [
  {
    name:"Arbitrum",
    logotext: arbitrum_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Optimism",
    logotext: optimism_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Polygon",
    logotext: polygon_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Ethereum",
    logotext: ethereum_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Bitcoin",
    logotext: '',
    logo: '',
    color: '',
  },
  {
    name:"TON",
    logotext: ton_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Cosmos",
    logotext: cosmos_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Injective",
    logotext: injective_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Osmosis",
    logotext: osmosis_logo_text,
    logo: '',
    color: '',
  },
  {
    name:"Solana",
    logotext: solana_logo_text,
    logo: '',
    color: '',
  }
]

type _partners = {
  _vechain: {
    logo: string,
    href: string,
  },
  _draperu: {
    logo: string,
    href: string,
  },
  _startuplisboa: {
    logo: string,
    href: string,
  },
}

export const partners:_partners = {
  _vechain: {
    logo: vechain,
    href: 'https://www.vechain.org/',
  },
  
  _startuplisboa: {
    logo: startuplisboa,
    href: 'https://www.startuplisboa.com/',
  },
  _draperu: {
    logo: draperu,
    href: 'https://draperuniversity.com/',
  },
}

export type _nft_evm_data = {
  chain: string,
  token_address: string,
  token_id: string,
  amount: number,
  contract_type: string,
  metadata: {
    name: string,
    description: string,
    animation_url: string | null,
    external_link: string | null,
    image_prb_ipfs: string,
    attributes: any,
  },
  token_uri: string,
  media: {
    low: string,
    medium: string | null,
    high: string | null
  }

}

export type _nft_evm_data_m = {
  Ethereum: _nft_evm_data[],
  Arbitrum: _nft_evm_data[],

  Polygon:  _nft_evm_data[],
  owner: string
}

export const nft_buttons = {
  sell: {
    text: "Sell",
    href: ""
  },
  gift: {
    text: "Gift",
    href: ""
  }
}