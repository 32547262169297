import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { nav, navigation, features, posts,} from '../components/data';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import izzy_logo from '../img/izzy_logo.jpg'
export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
            <header className="absolute inset-x-0 top-0 z-50 ">
                <nav className="flex items-center justify-between p-6 lg:px-8 mx-auto max-w-7xl px-6  lg:flex lg:items-center lg:gap-x-10 lg:px-8 " aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <img src={izzy_logo} className='h-16'/>
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {nav.map((item) => (
                            <a key={item.name} href={item.href} className="transition ease-in-out duration-300 hover:scale-125 focus:outline-none hover:ring hover:ring-violet-300 active:bg-violet-700 px-5 py-2 text-lg leading-5 rounded-full font-semibold text-white">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end invisible">
                        <a href="#" className="bg-violet-500 hover:bg-violet-600 focus:outline-none focus:ring focus:ring-violet-300 active:bg-violet-700 px-5 py-2 text-sm leading-5 rounded-full font-semibold text-white">
                            Launch App <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50 " />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                            <h1 className=" max-w-lg text-2xl font-montserrat font-bold tracking-tight text-white sm:text-2xl">IzzyLabs</h1>
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-white"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {nav.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            )}