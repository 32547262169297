
import {
  MagnifyingGlassIcon, AdjustmentsHorizontalIcon
} from '@heroicons/react/20/solid'
import {  userData, _encrypted_auth_data, _nft_evm_data_m, truncate } from './data'
import templogo from '../img/logo192.png'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

export default function Example(props:_nft_evm_data_m) {

  
  return (
    <>
            {/* Page header */}
            <div className="bg-gray-900 ">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <div className="pb-6 pt-3 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="min-w-0 flex-1">
                    {/* Profile */}
                    <div className="flex items-center">
                      <img
                        className="hidden h-16 w-16 rounded-full sm:block"
                        src={templogo}
                        alt=""
                      />
                      <div>
                        <div className="flex items-center">
                          <img
                            className="h-8 w-8 rounded-full sm:hidden"
                            src={templogo}
                            alt=""
                          />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-white sm:truncate sm:leading-9 ">
                            Hi, <span className='gradient-text'> {userData.telegram_data.username} </span>
                          </h1>

                        </div>
                        <dl className="grid grid-rows-2 sm:grid-cols-2 sm:ml-3 sm:mt-1 gap-1">
                          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-white sm:mr-6 sm:mt-0 ">
                            
                            Days with Izzy: <span className='font-light'> &nbsp;{ userData.telegram_data.id}</span>
                          </dd>
                          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-white sm:mr-6 sm:mt-0">
                            
                            Total NFTs on {truncate(props.owner)}: <span className='font-light'> &nbsp;88</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0 justify-between">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      All NFTs
                    </button>
                    <div className='grid grid-cols-2 gap-2'>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                      >
                        <AdjustmentsHorizontalIcon className="h-6 w-6"/>
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                      >
                        <MagnifyingGlassIcon className="h-6 w-6"/>
                      </button>
                    </div>
                    
                    
                    
                  </div>
                </div>
              </div>
            </div>

    </>
  )
}
