import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import axios from 'axios'
import {
  CheckCircleIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid'
import Sidebar from '../../components/sidebar'
import Profile from '../../components/profile_secondary'
import Profile_m from '../../components/profile'
import { navigation,  userData, _encrypted_auth_data, truncate,  _wallets, _nft_evm_data, _nft_evm_data_m, nft_buttons} from '../../components/data'
import { useParams,  } from "react-router-dom";
import Activity from '../../components/activity'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

function GetNftsEvm() :_nft_evm_data_m | undefined {
  const { wallet_address } = useParams<{wallet_address?: string}>();
  try {
    var nft_data;
    var nft_evm_data_sorted_arr:_nft_evm_data[] = []
    var nft_evm_data_sorted: _nft_evm_data_m = {
      Arbitrum: [],
      Ethereum: [],
      Polygon:  [],
      owner: ""
    }
    const local_nft_data = localStorage.getItem(wallet_address+"_evm")
    // console.log(local_nft_data)
    nft_data = JSON.parse(local_nft_data|| "") 
    // console.log(nft_data)
    nft_data.data.map((network:any) => {
      network.result.map((token:any) => {
        if (!token.hasOwnProperty("media") || !token.media.hasOwnProperty("media_collection")) {
          // console.log(token)
          return "error"
        }
        const nft:_nft_evm_data = {
          chain: network.chain,
          token_address: token.token_address,
          token_id: token.token_id,
          amount: token.amount,
          contract_type: token.contract_type,
          metadata: {
            name: token.normalized_metadata.name !== null ? token.normalized_metadata.name : token.name,
            description: token.normalized_metadata.description,
            animation_url: token.normalized_metadata.animation_url,
            external_link: token.normalized_metadata.external_link,
            image_prb_ipfs: token.normalized_metadata.image,
            attributes: token.normalized_metadata.attributes,
          },
          token_uri: token.token_uri,
          media: {
            low: token.media.media_collection.low.url? token.media.media_collection.low.url : "",
            medium: token.media.media_collection.medium.url? token.media.media_collection.medium.url : "",
            high: token.media.media_collection.high.url? token.media.media_collection.high.url : ""
          }
        }
        nft_evm_data_sorted_arr.push(nft)
        if (network.chain == 'eth' && typeof nft != null) nft_evm_data_sorted.Ethereum?.push(nft)
        else if (network.chain == 'arbitrum') nft_evm_data_sorted["Arbitrum"]?.push(nft)
        else if (network.chain == 'polygon') nft_evm_data_sorted["Polygon"]?.push(nft)
        if (typeof wallet_address == "string")
          nft_evm_data_sorted.owner = wallet_address
    }) })

    return nft_evm_data_sorted  

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
  }
}

function is_nft_evm_data_m(data : any): data is _nft_evm_data_m{
  return (data as _nft_evm_data_m) !== undefined;
}

function MakeLink(nft_data:_nft_evm_data) {
  const { wallet_address } = useParams<{wallet_address?: string}>();
  var link:string = "http://localhost:3000/dashboard/nft/" + (nft_data.token_address).toString() + "/" + (nft_data.token_id).toString() + "/" + (wallet_address)?.toString()
  return link
}

export default function DGallery() {
  const { wallet_address } = useParams<{wallet_address?: string}>();
  const [loading, setLoading] = useState(true);
  var nft_evm_data_sorted_arr:_nft_evm_data[] = []
  var nft_evm_data_sorted: _nft_evm_data_m = {
    Arbitrum: [],
    Ethereum: [],
    Polygon:  [],
    owner: ""
  }
  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        if (localStorage.getItem(wallet_address+"_evm")!== null) {
          setLoading(false); 
          return
        }
        const data =  await axios.get<any>(
          `http://localhost:8000/api/nfts/evm/all/${wallet_address}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
          },
        )
        console.log("promezh")
        // return nft_evm_data_sorted
        // JSON.stringify(nft_evm_data_sorted)
        localStorage.setItem(wallet_address+"_evm", JSON.stringify(data))     
        setLoading(false);     
          return data;
      
    }
  
    // call the function
     fetchData()
    .then((res) => {
      console.log()
    })
      // make sure to catch any error
      .catch(console.error);
  }, [])
    
  if (loading) {
    return (
    <>
      <div className="min-h-full">
        <Sidebar {...userData}/>
        <div className="flex flex-1 flex-col lg:px-64">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <Profile_m/>
            <div className="relative isolate pt-14">
                <div className="mx-auto max-w-7xl px-6 py-16 sm:py-32  lg:px-8 ">
                    <div className="mx-auto  lg:mx-0 ">
                        <h1 className="sm:mt-10 mt-2  text-5xl font-montserrat font-bold tracking-tight text-white text-center sm:text-7xl">
                        Loading...</h1>
                    </div>
                </div>
            </div>
            <Activity/>
          </main>
        </div>
      </div>
    </>
    );
  }
  var nft_data_blank: _nft_evm_data_m = {
    Ethereum: [],
    Arbitrum: [],
    Polygon:  [],
    owner: ""
  }
  var nft_data_parsed = GetNftsEvm()
  var n = is_nft_evm_data_m(nft_data_parsed) ? nft_data_parsed : nft_data_blank
  // console.log("n", n)
  var nft_data_parsed_v:_nft_evm_data_m = n

  
  return (
    <>
      <div className="min-h-full">
        <Sidebar {...userData}/>
        <div className="flex flex-1 flex-col lg:px-64">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <Profile {...nft_data_parsed_v}/>
            {Object.entries(nft_data_parsed_v).map(([chain_name, chain_data]) => (
            <div className=" sm:hidden" key={chain_name}>
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-xl font-medium leading-6 text-white" >{chain_name}</h2>
                <div className="flex flex-row carousel-wrapper md:mt-0 pb-0">
                  {Array.isArray(chain_data) ? chain_data.map((nft) => (
                    
                    <div  className="overflow-hidden rounded-3xl bg-gray-700 m-3 min-w-1/2" key={nft.token_address+"_"+nft.token_id}>
                      <a href={MakeLink(nft)}>
                      <div className="p-2 ">
                        <div className="flex items-center  justify-center items-center">
                          <div className="flex-shrink-0">
                          </div>
                          <div className=" w-0 flex-1 ">
                            <div className='h-150 overflow-hidden'>
                              <img src={nft.media.medium ? nft.media.medium : ""} className='object-cover  mx-auto rounded-3xl '/>
                            </div>
                            <dl className=' justify-center mt-3'>
                              <dt className="truncate text-md font-medium text-white font-montserrat font-medium">{nft.metadata.name}</dt>
                              <dt className="truncate text-md font-medium text-white font-montserrat font-light">{truncate(nft.token_address)}, {nft.token_id}</dt>

                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-800 p-3 grid grid-cols-2 gap-3 items-center justify-items-center ">
                        {Object.entries(nft_buttons).map(([button_name, button_data]) => (
                        <div className=" rounded-full bg-gray-700 w-full h-8 items-center text-center flex justify-center">
                          <a href={button_data.href} className="font-medium text-white hover:text-gray-500 text-lg font-montserrat font-normal" >
                            {button_data.text}
                          </a>
                        </div>
                        ))}
                      </div>
                      </a>
                    </div>
                   
                  )) : []}
                </div>
              </div>
            </div>
            ))}
            {Object.entries(nft_data_parsed_v).map(([chain_name, chain_data]) => (
            <div className="mt-8 hidden sm:block" key={chain_name}>
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-xl font-medium leading-6 text-white" >{chain_name}</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 grid-cols-2 lg:grid-cols-3">
                  {Array.isArray(chain_data) ? chain_data.map((nft) => (
                    <div  className="overflow-hidden rounded-3xl bg-gray-700 min-w-2/3" key={nft.token_address+"_"+nft.token_id}>
                      <div className="p-3 ">
                        <div className="flex items-center  justify-center items-center">
                          <div className="flex-shrink-0">
                          </div>
                          <div className=" w-0 flex-1 ">
                            <img src={nft.media.medium ? nft.media.medium : ""} className='object-none mx-auto rounded-3xl h-3/4'/>
                            <dl className=' justify-center mt-3'>
                              <dt className="truncate text-md font-medium text-white">name: {nft.metadata.name}</dt>
                              <dt className="truncate text-md font-medium text-white ">info: {truncate(nft.token_address)}, {nft.token_id}</dt>

                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-800 p-3 grid grid-cols-2 gap-3 items-center justify-items-center ">
                        {Object.entries(nft_buttons).map(([button_name, button_data]) => (
                        <div className=" rounded-full bg-gray-700 w-full h-8 items-center text-center flex justify-center">
                          <a href={button_data.href} className="font-medium text-white hover:text-gray-500 text-lg">
                            {button_data.text}
                          </a>
                        </div>
                        ))}
                      </div>
                    </div>
                  )) : []}
                </div>
                  
              </div>
            </div>))}
            {/* <Activity/> */}
          </main>
        </div>
      </div>
    </>
  )
}
