import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import axios from 'axios'
import {
  CheckCircleIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid'
import Sidebar from '../../components/sidebar'
import Profile from '../../components/profile_secondary'
import Profile_m from '../../components/profile'
import { navigation,  userData, _encrypted_auth_data, truncate,  _wallets, _nft_evm_data, _nft_evm_data_m, nft_buttons} from '../../components/data'
import { useParams,  } from "react-router-dom";
import Activity from '../../components/activity'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const nft_data_blank = 
{
  chain: "",
  token_address: "",
  token_id: "",
  amount: 1,
  contract_type: "ERC721" ,
  metadata: {
    name: "",
    description: "",
    animation_url: "",
    external_link: "",
    image_prb_ipfs: "",
    attributes: "",
  },
  token_uri: "",
  media: {
    low: "",
    medium: "",
    high: ""
  }

}

function GetNftsEvm() :_nft_evm_data | undefined {
  const { token_address, token_id, wallet_address } = useParams<{token_address?: string, token_id?: string, wallet_address?: string}>();
  try {
    var nft_data
    var nft_arr: _nft_evm_data[] = []
    const local_nft_data = localStorage.getItem(wallet_address+"_evm")
    // console.log(local_nft_data)
    nft_data = JSON.parse(local_nft_data|| "") 
    // console.log(nft_data)
    nft_data.data.map((network:any) => {
      network.result.map((token:any) => {
        if (token.token_address == token_address && token.token_id == token_id) {
          var nft:_nft_evm_data = {
            chain: network.chain,
            token_address: token.token_address,
            token_id: token.token_id,
            amount: token.amount,
            contract_type: token.contract_type,
            metadata: {
              name: token.normalized_metadata.name !== null ? token.normalized_metadata.name : token.name,
              description: token.normalized_metadata.description,
              animation_url: token.normalized_metadata.animation_url,
              external_link: token.normalized_metadata.external_link,
              image_prb_ipfs: token.normalized_metadata.image,
              attributes: token.normalized_metadata.attributes,
            },
            token_uri: token.token_uri,
            media: {
              low: token.media.media_collection.low.url? token.media.media_collection.low.url : "",
              medium: token.media.media_collection.medium.url? token.media.media_collection.medium.url : "",
              high: token.media.media_collection.high.url? token.media.media_collection.high.url : ""
            }
          }
          nft_arr.push(nft)
        }
    }) })
    console.log(nft_arr)
    return nft_arr[0]

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
  }
}

function is_nft_evm_data(data : any): data is _nft_evm_data{
  return (data as _nft_evm_data) !== undefined;
}

function MakeLink(link:any) : link is string {
  return link.toString()
}

export default function DGallery() {
  const { token_address, token_id, wallet_address } = useParams<{token_address?: string, token_id?: string, wallet_address?: string}>();


  var nft_data_parsed = GetNftsEvm() 
  console.log(nft_data_parsed)
  const nft_data_parsed_v = is_nft_evm_data(nft_data_parsed) ? nft_data_parsed : nft_data_blank
  console.log(nft_data_parsed_v)
  
  return (
    <>
      <div className="min-h-full">
        <Sidebar {...userData}/>
        <div className="flex flex-1 flex-col lg:px-64">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <img src={nft_data_parsed_v.media.high ? nft_data_parsed_v.media.high : ""} className='rounded-3xl  w-9/12 m-auto my-12 shadow'></img>
            <div className="ml-3 text-2xl font-semibold leading-7 text-white sm:truncate sm:leading-9 mb-5"> <span className='font-light gradient-text'>{nft_data_parsed_v.metadata.name} #{nft_data_parsed_v.token_id}</span></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 "> <span className='font-light'>{nft_data_parsed_v.metadata.description}</span></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">Chain: <span className='font-light'>{nft_data_parsed_v.chain}</span></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">Contract Type: <span className='font-light'>{nft_data_parsed_v.contract_type}</span></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">Blockchain Info: <span className='font-light'>{truncate(nft_data_parsed_v.token_address)}, {nft_data_parsed_v.token_id}</span></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">URI: <a href={nft_data_parsed_v.token_uri} className='text-blue-700 underline'> link</a></div>
            <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">Attributes: </div>
            {nft_data_parsed_v.metadata.attributes.map((trait:any) => (
              <div className='ml-4'>
                <div className="ml-3 text-xl font-medium leading-7 text-white sm:truncate sm:leading-9 ">{trait.trait_type} : <span className='font-light'>{trait.value}</span> </div>

              </div>
            ))}
            
            {/* <Activity/> */}
          </main>
        </div>
      </div>
    </>
  )
}
