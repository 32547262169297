import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { useMemo } from 'react'
//solana
import { WalletAdapterNetwork  } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, GlowWalletAdapter, TrustWalletAdapter, ExodusWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter  } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
//solana

//cosmos
// import { ChainProvider } from '@cosmos-kit/react';
// import { chains, assets } from 'chain-registry';
// import { wallets as keplr } from '@cosmos-kit/keplr';
//cosmos

//pages
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import Auth from "./pages/Auth";
import Solana from './pages/Solana'
import EVM from './pages/EVM'
import Cosmos from './pages/Cosmos'
import DHome from './pages/Dashboard/DHome';
import DSettings from './pages/Dashboard/DSettings';
import DGallery from './pages/Dashboard/DGallery';
import DAssets from './pages/Dashboard/DAssets';
import DWallets from './pages/Dashboard/DWallets';
import DActivity from './pages/Dashboard/DActivity';
import DNFT from './pages/Dashboard/DNFT_page'
//pages

//evm
import { WagmiConfig, createConfig, useAccount } from "wagmi"; 
import { ConnectKitProvider, ConnectKitButton, getDefaultConfig } from "connectkit"; 
//evm


//evm
const config = createConfig(
  getDefaultConfig({
    // Required API Keys
    alchemyId: 'NfUr-Ro_2O6kqrDfaYobjq7WEBcuVr-8', // or infuraId
    walletConnectProjectId: 'fd551ef50da8814fb148b90c72200e26',

    // Required
    appName: "IzzyLabs",

    // Optional
    appDescription: "Web3 onboarding ",
    appUrl: "https://izzylabs.com", // your app's url
    appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  }),
);
//evm



function App() {
  //solana
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
      () => [
          new PhantomWalletAdapter(),
          new GlowWalletAdapter(),
          new TrustWalletAdapter(),
          new ExodusWalletAdapter(),
          new SlopeWalletAdapter(),
          new SolflareWalletAdapter(),
      ],
      [network]
  );
  //solana

  return (
    <div className="App">
       <WagmiConfig config={config} > {/*evm */}
        <ConnectKitProvider mode="light" customTheme={{"--ck-body-color": "#000000","--ck-connectbutton-font-size": "1.125rem",}}>{/*evm */}
          <WalletProvider wallets={wallets}> {/*sol */}
            <WalletModalProvider>{/*sol */}
              
                <Router>
                  <Routes>
                       
                    <Route path="/" element={<Home/>}/>{/*home page */}
                    <Route path='/auth/solana/:hash' element={<Solana/>}/>{/*solana auth page */}
                    <Route path='/auth/evm/:hash' element={<EVM/>}/>{/*evm auth page */}
                    <Route path='/auth/atom/:hash' element={<Cosmos/>}/>{/*bitcoin auth page */}
                    <Route path='/auth/:hash' element={<Auth/>}/>{/*auth selection page */}
                    <Route path='/dashboard/home/' element={<DHome/>}/>{/*dashboard */}
                    <Route path='/dashboard/wallets/' element={<DWallets/>}/>{/*dashboard */}
                    <Route path='/dashboard/settings/' element={<DSettings/>}/>{/*dashboard */}
                    <Route path='/dashboard/assets/:wallet_address' element={<DAssets/>}/>{/*dashboard */}
                    <Route path='/dashboard/nfts/:wallet_address' element={<DGallery/>}/>{/*dashboard */}
                    <Route path='/dashboard/activity/' element={<DActivity/>}/>{/*dashboard */}
                    <Route path='/dashboard/nft/:token_address/:token_id/:wallet_address' element={<DNFT/>}/>{/*dashboard */}

                    <Route path="*" element={<ErrorPage />} />{/*error page */}
                  </Routes>
                </Router>
            </WalletModalProvider>{/*sol */}
          </WalletProvider >{/*sol */}
        </ConnectKitProvider>{/*evm */}
      </WagmiConfig>{/*evm */}
    </div>

  );
}

export default App;
